.home_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4em;
}

.home_container h1 {
    font-size: 2.5em;
    margin-bottom: .5em;
}

.home_container h1 span {
    color: #ffbb33;
    padding: 0 .2em;
    background-color: #222;
}

.home_container p {
    margin-bottom: 1.5em;
    color: #7b7b7b;
}

.home_container img {
    width: 350px;
    margin: 2em 0;
}