* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html, body, #root, .App {
  background-color: #efefef;
  height: 100%;
}

ul {
  display: flex;
  list-style: none;
}

