.btn {
    background-color: #222;
    color: #fff;
    padding: .5em 1em;
    text-decoration: none;
    border: 2px solid #222;
    transition: .5s;
}

.btn:hover {
    color: #ffbb33;
    border-color: #ffbb33;
}